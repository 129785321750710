(function ($) {
  var conditionsForEnablement = generic.env.parsedQuery().socialshoppe || generic.cookie('socialshoppe_session');
  if (!conditionsForEnablement) {
    return;
  }

  if (generic.env.parsedQuery().socialshoppe) {
    generic.cookie('socialshoppe_session', JSON.stringify({
      label: decodeURIComponent(generic.env.parsedQuery().label),
      redirect: decodeURIComponent(generic.env.parsedQuery().redirect),
    }), {
      expires: '',
      path: '/'
    });

    // one time product handler
    if (generic.env.parsedQuery().products) {
      let skuPairs = generic.env.parsedQuery().products.split(',');
      let skuBaseIds = [];
      let cartClearCallbackFn = function() {
        let objectParam = {
          skuBaseId: skuBaseIds,
          SKU_BASE_ID: skuPairs,
          skus: skuPairs,
        };
        if (prodcat && prodcat.ui && prodcat.ui.addToCart) {
          prodcat.ui.addToCart(objectParam);
        } else if (site && site.addToCart) {
          site.addToCart(objectParam);
        }
      };
      skuPairs.forEach(pair => {
        let skuQtyPair = pair.split(':');
        if (skuQtyPair[0]) {
          skuBaseIds.push(skuQtyPair[0]);
        }
      });
      $(window).one('prodcat:UiInitialized.socialShopping socialShopping.init', function(e) {
        generic.jsonrpc.fetch({
          method : 'cart.empty',
          onSuccess: function(jsonRpcResponse) {
            cartClearCallbackFn();
          },
          onFailure: function(jsonRpcResponse) {
            cartClearCallbackFn();
          }
        });
      });
    }
  }
  window.socialshoppe = true;
})(jQuery);
